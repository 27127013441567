import React, { FC } from 'react';
import { Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Image from 'common/Image';

import { stripHTML } from '../../utils/functions';
import { ProductListItemProps } from './models';

import './ProductListItem.scss';

const ProductListItem: FC<ProductListItemProps> = ({
  items,
  cols,
  titleProductList,
  showTitle,
}) => (
  <div className="row product-list-item--container">
    {items.map((item) => (
      <div
        data-testid="product-list-item"
        className={`product-list-item col-${12 / cols} col-md-3 col-lg-2`}
        key={item.id}
      >
        <div className="product-list-item__container px-2 px-md-0">
          <div className="product-list-item__image mx-auto">
            <a href={item.url}>
              <Image imageData={item.image} alt={item.image.altText} />
            </a>
          </div>
          <h5 className="home mx-auto">
            <Link
              to={item.url}
              className={`color clr-${item.cssExtraClass || 'color clr-transparent'}`}
            >
              {item.productListTitle
                ? stripHTML(item.productListTitle)
                : stripHTML(item.titleFlavor)}
            </Link>
          </h5>
          {showTitle && (
            <h2 className="product-list-item__title mt-3 mt-md-0 mb-0">
              <Link
                to={item.url}
                className={`color clr-${item.cssExtraClass || 'color clr-transparent'}`}
              >
                {titleProductList || item.title}
              </Link>
            </h2>
          )}

          <h5 className={`products clr-${item.cssExtraClass || 'color clr-transparent'}`}>
            <Link
              to={item.url}
              className={`color clr-${item.cssExtraClass || 'color clr-transparent'}`}
            >
              {stripHTML(item.subtitle)}
            </Link>
          </h5>
          {item.productExtraInfo ? (
            <DangerouslySetInnerHtml
              html={item.productExtraInfo}
              element="h2"
              className="mx-auto legal-product"
            />
          ) : (
            <DangerouslySetInnerHtml
              html={item.cssExtraClass}
              element="h2"
              className="mx-auto legal-product-none invisible"
            />
          )}
        </div>
        <div className={`color ${item.cssExtraClass || 'mx-auto'}`}>
          {item.ctaTextSliderProducts ? (
            <Link to={item.url} className="text-decoration-none">
              <DangerouslySetInnerHtml
                html={item.ctaTextSliderProducts}
                element="h2"
                className={`mb-0 mx-auto clr-${item.cssExtraClass}`}
              />
            </Link>
          ) : null}
        </div>
      </div>
    ))}
  </div>
);
export default ProductListItem;
